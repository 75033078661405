export default class TransactionParameters {
  /**
   * @param {String} merchantParameters
   * @param {String} signatureVersion
   * @param {string} signature
   */
  constructor(merchantParameters = null, signatureVersion = null, signature = null) {
    this.merchantParameters = merchantParameters;
    this.signatureVersion = signatureVersion;
    this.signature = signature;
  }

  isValid() {
    return this.merchantParameters && this.signatureVersion && this.signature;
  }

  static create(entity) {
    const { ds_merchant_parameters, ds_signature_version, ds_signature } = entity;
    return new TransactionParameters(ds_merchant_parameters, ds_signature_version, ds_signature);
  }
}
