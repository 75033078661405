import { api } from '.';
import { WALLETS_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import socialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';

export const getResources = clientId => ({
  [CLIENT_RESOURCE]: clientId,
  [WALLETS_RESOURCE]: undefined,
});

/**
 * @param {string} clientId
 * @returns {Promise.<SocialAudienceClient>}
 */
export async function getWalletByClient(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources(clientId));
  params.addInclude('user');
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(socialAudienceEntityParser.parse(data), data.meta).build();
}
