import { api } from '.';
import { WALLETS_RESOURCE, TRANSACTION_RESOURCE, VIRTUAL_RESOURCE, ALLOCATE_RESOURCE } from '@/services/keys';

export const getResources = walletId => ({
  [WALLETS_RESOURCE]: walletId,
  [TRANSACTION_RESOURCE]: undefined,
  [VIRTUAL_RESOURCE]: undefined,
  [ALLOCATE_RESOURCE]: undefined,
});

/**
 * @param {string} clientId
 * @param {Wallet} wallet
 * @returns {Promise}
 */
export function newVirtualTransaction(walletId, amount) {
  const partialUrl = api.createUrl(getResources(walletId));
  return api.create(partialUrl, amount);
}
