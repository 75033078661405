import { api } from '.';
import { WALLETS_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import socialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';

export const getResources = walletId => ({
  [WALLETS_RESOURCE]: walletId,
});

export async function getWalletById(walletId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources(walletId));
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(socialAudienceEntityParser.parse(data), data.meta).build();
}
