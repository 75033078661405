import { getTransactionParameters } from './getTransactionParameters';
import { getWalletByClient } from './getWalletByClient';
import { changeAutomaticRecharge } from './changeAutomaticRecharge';
import { newVirtualTransaction } from './newVirtualTransaction';
import { getWalletById } from './getWalletById';
import { socialAudience } from '..';

export const api = socialAudience;

export { getTransactionParameters, getWalletByClient, changeAutomaticRecharge, newVirtualTransaction, getWalletById };
