import { api } from '.';
import { WALLET_RESOURCE, CLIENT_RESOURCE, AUTOMATIC_RECHARGE_RESOURCE } from '@/services/keys';

export const getResources = clientId => ({
  [CLIENT_RESOURCE]: clientId,
  [WALLET_RESOURCE]: undefined,
  [AUTOMATIC_RECHARGE_RESOURCE]: undefined,
});

/**
 * @param {string} clientId
 * @param {boolean} automaticRecharge
 * @returns {Promise.<ResponseBuilder>}
 */
export function changeAutomaticRecharge(clientId, automaticRecharge) {
  const partialUrl = api.createUrl(getResources(clientId));
  return api.update(partialUrl, { automaticRecharge });
}
