import { api } from '.';
import { WALLETS_RESOURCE, TRANSACTION_RESOURCE, PARAMS_RESOURCE } from '@/services/keys';
import TransactionParameters from '@/entities/socialAudience/TransactionParameters';

export const getResources = walletId => ({
  [WALLETS_RESOURCE]: walletId,
  [TRANSACTION_RESOURCE]: undefined,
  [PARAMS_RESOURCE]: undefined,
});

/**
 * @param {string} clientId
 * @param {IncomeParameters} incomeParameters
 * @returns {Promise.<ResponseBuilder>}
 */
export async function getTransactionParameters(clientId, incomeParameters) {
  const partialUrl = api.createUrl(getResources(clientId));
  const { data } = await api.create(partialUrl, incomeParameters.payload());
  return TransactionParameters.create(data);
}
